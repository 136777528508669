import {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  useQuery,
} from '@tanstack/react-query'

interface Meta {
  errorMessage?: string
}

export type RequestDataOptions = Omit<
  UseQueryOptions<any, unknown, any, string[]>,
  'queryKey' | 'queryFn' | 'retry'
>

interface RequestData {
  options?: RequestDataOptions
  queryFn: QueryFunction<any, any>
  queryKey: QueryKey
  queryParams?: any
  meta?: Meta
  retry?: boolean | number
}

export const useRequestQuery = (requestData: RequestData): any => {
  return useQuery({
    queryKey: requestData.queryKey,
    queryFn: () => requestData.queryFn(requestData.queryParams),
    retry: requestData.retry ?? false,
    ...requestData.options,
  } as UseQueryOptions)
}
