// 인터페이스와 API 들은 파일로 따로 구분하면 될 것 같습니다.
// 초반에 이런걸 많이 만들어놓으면 일이 조금은 적어지지 않을까 기대를 해봅니다

import { getCookie } from 'cookies-next'
import { signOut } from 'next-auth/react'
import { Error, errorData } from './error'

const baseUrl = process.env.NEXT_PUBLIC_API_URL
const mockBaseUrl = process.env.NEXT_PUBLIC_MOCK_API_URL

export enum METHOD {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'PATCH',
  DELETE = 'delete',
  UPLOAD = 'upload',
}

export interface ApiResponse<T> {
  error?: Error
  result?: T
  body?: any
}

// // 로그인토큰 체크 후 갱신 / 패스
// const checkLoginToken = async (): Promise<boolean> => {
//   // 쿠키에 토큰있는지 확인
//   const loginToken = useUserStore((state: any) => state.accessToken)

//   // 토큰이 있을 경우 토큰을 가져와서 디코딩, 만료일 체크
//   if (loginToken) {
//     const tokenData: any = jwt.decode(loginToken)
//     const exp = tokenData?.exp

//     // 만료일 지났을 경우 리프레시토큰 체크
//     return exp * 1000 < new Date().getTime()
//   }

//   return false
// }

// // 리프레시토큰 체크 후 로그아웃 / 패스
// const checkRefreshToken = async (): Promise<boolean> => {
//   // 쿠키에 리프레시토큰이 있는지 확인
//   const refreshToken = useUserStore((state: any) => state.refreshToken)

//   // 토큰이 있을 경우 토큰을 가져와서 디코딩, 만료일 체크
//   if (refreshToken) {
//     const tokenData: any = jwt.decode(refreshToken)
//     const exp = tokenData?.exp

//     if (exp * 1000 < new Date().getTime()) {
//       // accessToken 재발급
//       const response = await fetch(`${baseUrl}/v1/auth/refresh`, {
//         method: 'POST',
//         cache: 'no-store',
//         headers: {
//           Accept: 'application/json',
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ refreshToken }),
//       })
//       if (response.ok) {
//         const result = await response.json()
//         if (result && result.accessToken) {
//           const t: any = jwt.decode(result.accessToken)
//           // console.log('#result token', t)
//           const updateAccessToken = useUserStore(
//             (state: any) => state.updateAccessToken,
//           )
//           updateAccessToken(result.accessToken)
//           return true
//         } else {
//           console.log('로그아웃')
//         }
//       } else {
//         console.log('로그아웃')
//       }
//     } else {
//       // 로그아웃
//       console.log('로그아웃')
//     }
//   }

//   // 로그아웃

//   return false
// }

// const interceptors = {
//   request: async () => {
//     // accessToken 체크
//     console.log('# request')

//     const isLoginToken = await checkLoginToken()

//     if (!isLoginToken) {
//       const isRefreshToken = await checkRefreshToken()
//       console.log('isRefreshToken', isRefreshToken)

//       if (!isRefreshToken) {
//         // 로그아웃
//         console.log('로그아웃')
//         await cookieAction.deleteCookie('_at')
//         await cookieAction.deleteCookie('_rt')
//         return false
//       }
//     }

//     return true
//   },
//   response: async (tokenError: any) => {
//     // accessToken 체크
//     console.log('# response', tokenError)
//     let isRetry = false

//     if (tokenError.errorCode === 'TOKEN_EXPIRED') {
//       const isTokenUpdate = await interceptors.request()
//       if (!isTokenUpdate) {
//         window.location.href = '/login'
//         isRetry = false
//       }
//       isRetry = true
//     }

//     return isRetry
//   },
// }

export async function request(
  url: string,
  method: METHOD,
  params?: any,
  opt?: any,
  retryCount = 0,
): Promise<ApiResponse<any>> {
  // const session = await getSession()
  // console.log('#session', session)
  // const session = useSession()
  // console.log('token', session.data?.accessToken)
  const apiUrl = opt?.mock
    ? new URL(`${mockBaseUrl}${url}`)
    : url.includes('http')
      ? new URL(url)
      : new URL(`${baseUrl}${url}`)
  let options: any = {
    method,
    cache: 'no-store',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }

  if (opt) {
    options = {
      ...options,
      ...opt,
    }
  }

  switch (method) {
    case METHOD.GET:
      apiUrl.search = new URLSearchParams(params).toString()
      break
    case METHOD.DELETE:
      apiUrl.search = new URLSearchParams(params).toString()
      options.body = JSON.stringify(params)
      break
    case METHOD.UPLOAD:
      options.method = METHOD.POST
      options.body = params
      options.headers = {
        ContentType: 'multipart/form-data',
      }
      break
    case METHOD.POST:
    case METHOD.PUT:
    case METHOD.PATCH:
      if (params.queryString) {
        apiUrl.search = new URLSearchParams(params.queryString).toString()
        const body = params
        delete body.queryString
        options.body = JSON.stringify(params)
      } else {
        options.body = JSON.stringify(params)
      }

      break
  }
  if (getCookie('token')) {
    options.headers.Authorization = `Bearer ${getCookie('token')}`
  }

  // if (!url.includes('/v1/auth')) {
  //   await interceptors.request()
  // }

  // console.log(apiUrl, options)

  try {
    const response = await fetch(apiUrl, options)

    // console.log(url, response)

    // status 200
    if (response.ok) {
      try {
        // readablStream은 blob으로 반환
        // response.json() 요청시 readablStream의 locked가 true로 변경되어 다시 사용할 수 없음
        // responseType를 구분하기 위해서 response.headers의 content-type을 확인
        const contentType = response.headers.get('content-type')
        // console.debug('contentType---', contentType)
        let resultJson =
          contentType === 'application/octet-stream'
            ? await response.blob()
            : await response.json()
        return {
          result: resultJson || response.body,
        }
      } catch (e: any) {
        console.log('json parse error', e)
        return {
          result: true,
          body: response.body,
        }
      }
    } else {
      // if (!url.includes('/v1/auth')) {
      //   const isRetry = await interceptors.response(json.error)

      //   if (isRetry && retryCount < 2) {
      //     return new Promise((resolve) =>
      //       setTimeout(() => {
      //         resolve(request(url, method, params, ++retryCount))
      //       }, 1000),
      //     )
      //   }
      // }

      const { error }: { error: Error } = await response.json()

      // accessToken 만료일 경우 갱신)
      if (
        ['AUTHORIZATION_MISSING', 'TOKEN_EXPIRED'].includes(error.errorCode)
      ) {
        const test = () => {}

        signOut({
          callbackUrl: '/login',
        })

        // const token = useUserStore((state: any) => state.token)
        // const tokenResult = await userApi.refreshToken(token)
      }
      // refreshToken 만료일 경우 로그아웃

      console.log('#####', error)
      throw error
    }
  } catch (e: any) {
    throw (e?.message === 'Failed to fetch' && errorData.OFFLINE_NETWORK) || e
  }
}

export async function get(
  url: string,
  params?: any,
  config?: any,
): Promise<ApiResponse<any>> {
  return request(url, METHOD.GET, params, config)
}

export async function post(
  url: string,
  body?: any,
  config?: any,
): Promise<ApiResponse<any>> {
  return request(url, METHOD.POST, body, config)
}

export async function put(
  url: string,
  params?: any,
  config?: any,
): Promise<ApiResponse<any>> {
  return request(url, METHOD.PUT, params, config)
}

export async function patch(
  url: string,
  params?: any,
  config?: any,
): Promise<ApiResponse<any>> {
  return request(url, METHOD.PATCH, params, config)
}

export async function upload(
  url: string,
  formData: FormData,
  config?: any,
): Promise<ApiResponse<any>> {
  return request(url, METHOD.UPLOAD, formData, config)
}

// delete는 예약어로 지정되어 있어서 _delete로 메서드 생성후에 delete로 반환하도록 했습니다
async function _delete(
  url: string,
  params?: any,
  config?: any,
): Promise<ApiResponse<any>> {
  return request(url, METHOD.DELETE, params, config)
}
export { _delete as delete }
